ion-modal {
  z-index: 900 !important;
}
ion-modal::part(backdrop) {
  opacity: 1 !important;
  background-color: var(--ysh-color-backdrop) !important;
}
ion-modal::part(content) {
  border-radius: var(--ysh-radius-md) !important;
  box-shadow: 0 20px 20px rgba(10,20,30,0.1) !important;
}
