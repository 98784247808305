// MATERIAL CUSTOM GLOBAL STYLES

:root {
  --mdc-plain-tooltip-container-color: var(--ysh-color-space);
  --mdc-outlined-text-field-container-shape: var(--ysh-radius-sm);
  --mat-datepicker-calendar-container-shape: var(--ysh-radius-sm);
  --mat-datepicker-calendar-container-elevation-shadow: var(--ysh-shadow-lg);
}

mat-form-field {
  max-height: fit-content;
  
  &[size-small] {
    --mat-form-field-container-vertical-padding: 0;
    --mat-form-field-container-height: 2.25rem;
    --mat-form-field-container-text-line-height: 2.1875rem;
    --mat-form-field-container-text-size: var(--ysh-font-size-xs);
    --mdc-outlined-text-field-container-shape: var(--ysh-radius-xs);
  }
  &:has(input:read-only) {
    pointer-events: none;
    input:read-only {
      opacity: 0.4;
    }
    label {
      pointer-events: none !important;
    }
  }
  label {
    margin-top: -2px; // tweak vertical alignment
  }
  mat-hint {
    display: none;
  }
  &:has(input:focus) mat-hint {
    display: block;
    color: var(--ysh-color-blue);
    animation: fadeIn 0.5s;
  }
}

mat-datepicker-toggle {
  margin-right: 0.5rem; // more space before field end
}

mat-icon.mat-icon {
  width: fit-content;
  height: fit-content;
  font-size: inherit;

  &[size-small] {
    font-size: 1rem;
  }
  &[size-medium] {
    font-size: 1.125rem;
  }
  &[size-large] {
    font-size: 1.5rem;
  }
}
