@import 'fonts.scss';
@import 'animate.scss';
@import 'mat-theme.scss';
@import 'mat-custom.scss';
@import 'ion-custom.scss';

:root {
  --ysh-font-size-xl: 2rem;
  --ysh-font-size-lg: 1.5rem;
  --ysh-font-size-md: 1.25rem;
  --ysh-font-size-base: 1rem;
  --ysh-font-size-sm: 0.875rem;
  --ysh-font-size-xs: 0.75rem;
  
  --ysh-radius-xl: 0.75rem;
  --ysh-radius-lg: 0.625rem;
  --ysh-radius-md: 0.5rem;
  --ysh-radius-sm: 0.375rem;
  --ysh-radius-xs: 0.25rem;

  --ysh-duration-xl: 1s;
  --ysh-duration-lg: 0.8s;
  --ysh-duration-md: 0.6s;
  --ysh-duration-sm: 0.4s;
  --ysh-duration-xs: 0.2s;

  --ysh-shadow-xl: 0 6px 10px rgba(0,0,0,0.05);
  --ysh-shadow-lg: 0 4px 8px rgba(0,0,0,0.05);
  --ysh-shadow-md: 0 2px 6px rgba(0,0,0,0.05);
  --ysh-shadow-sm: 0 1px 4px rgba(0,0,0,0.05);
  --ysh-shadow-xs: 0 1px 2px rgba(0,0,0,0.05);

  --ysh-color-blue: rgb(0,100,255);
  --ysh-color-mint: rgb(0,210,160);
  --ysh-color-coral: rgb(255,110,95);
  --ysh-color-canary: rgb(255,210,0);
  --ysh-color-stripe: rgb(0,180,255);
  --ysh-color-space: rgb(15,35,65);
  --ysh-color-shadow: rgb(45,65,95);
  --ysh-color-slate: rgb(85,105,135);
  --ysh-color-silver: rgb(180,190,205);
  --ysh-color-smoke: rgb(242,243,245);
  --ysh-color-sky: rgb(230,245,255);
  --ysh-color-subwhite: rgb(250,250,251);
  --ysh-color-border: rgb(230,232,235);
  --ysh-color-backdrop: rgba(10,20,30,0.5);

  --padding-outer: min(6vw, 2rem);
}
::before, ::after, * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-family: 'YoshiSans';
  font-feature-settings: 'tnum';
  font-size: 16px;
  font-weight: 300;
  line-height: 1.3;
  color: var(--ysh-color-space);
  overscroll-behavior: none;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  color: inherit;
}
img, video {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  vertical-align: middle;
}
h1 {
  font-size: var(--ysh-font-size-xl);
  font-weight: 700;
  line-height: normal;
}
h2 {
  font-size: var(--ysh-font-size-lg);
  font-weight: 600;
  line-height: normal;
}
h3 {
  font-size: var(--ysh-font-size-md);
  font-weight: 600;
  line-height: normal;
}
h4 {
  font-size: var(--ysh-font-size-base);
  font-weight: 500;
  line-height: normal;
}
h5 {
  font-size: var(--ysh-font-size-sm);
  font-weight: 500;
  line-height: normal;
}
h6 {
  font-size: var(--ysh-font-size-xs);
  font-weight: 400;
  line-height: normal;
}
p {
  font-size: var(--ysh-font-size-base);
  font-weight: 300;
  line-height: normal;
}
a {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
  &[underline] {
    text-decoration: underline;
  }
}
b {
  font-weight: 700;
}
i {
  font-style: italic;
}
::placeholder {
  color: var(--ysh-color-shadow);
}
