@font-face {
  font-family: 'YoshiSans';
  font-weight: 100;
  font-style: normal;
  src: url('/assets/fonts/yoshi-sans-100.woff') format('woff');
}
@font-face {
  font-family: 'YoshiSans';
  font-weight: 200;
  font-style: normal;
  src: url('/assets/fonts/yoshi-sans-200.woff') format('woff');
}
@font-face {
  font-family: 'YoshiSans';
  font-weight: 300;
  font-style: normal;
  src: url('/assets/fonts/yoshi-sans-300.woff') format('woff');
}
@font-face {
  font-family: 'YoshiSans';
  font-weight: 400;
  font-style: normal;
  src: url('/assets/fonts/yoshi-sans-400.woff') format('woff');
}
@font-face {
  font-family: 'YoshiSans';
  font-weight: 500;
  font-style: normal;
  src: url('/assets/fonts/yoshi-sans-500.woff') format('woff');
}
@font-face {
  font-family: 'YoshiSans';
  font-weight: 600;
  font-style: normal;
  src: url('/assets/fonts/yoshi-sans-600.woff') format('woff');
}
@font-face {
  font-family: 'YoshiSans';
  font-weight: 700;
  font-style: normal;
  src: url('/assets/fonts/yoshi-sans-700.woff') format('woff');
}
@font-face {
  font-family: 'YoshiSans';
  font-weight: 800;
  font-style: normal;
  src: url('/assets/fonts/yoshi-sans-800.woff') format('woff');
}
@font-face {
  font-family: 'YoshiSans';
  font-weight: 900;
  font-style: normal;
  src: url('/assets/fonts/yoshi-sans-900.woff') format('woff');
}
@font-face {
  font-family: 'YoshiSans';
  font-weight: 100;
  font-style: italic;
  src: url('/assets/fonts/yoshi-sans-100-italic.woff') format('woff');
}
@font-face {
  font-family: 'YoshiSans';
  font-weight: 200;
  font-style: italic;
  src: url('/assets/fonts/yoshi-sans-200-italic.woff') format('woff');
}
@font-face {
  font-family: 'YoshiSans';
  font-weight: 300;
  font-style: italic;
  src: url('/assets/fonts/yoshi-sans-300-italic.woff') format('woff');
}
@font-face {
  font-family: 'YoshiSans';
  font-weight: 400;
  font-style: italic;
  src: url('/assets/fonts/yoshi-sans-400-italic.woff') format('woff');
}
@font-face {
  font-family: 'YoshiSans';
  font-weight: 500;
  font-style: italic;
  src: url('/assets/fonts/yoshi-sans-500-italic.woff') format('woff');
}
@font-face {
  font-family: 'YoshiSans';
  font-weight: 600;
  font-style: italic;
  src: url('/assets/fonts/yoshi-sans-600-italic.woff') format('woff');
}
@font-face {
  font-family: 'YoshiSans';
  font-weight: 700;
  font-style: italic;
  src: url('/assets/fonts/yoshi-sans-700-italic.woff') format('woff');
}
@font-face {
  font-family: 'YoshiSans';
  font-weight: 800;
  font-style: italic;
  src: url('/assets/fonts/yoshi-sans-800-italic.woff') format('woff');
}
@font-face {
  font-family: 'YoshiSans';
  font-weight: 900;
  font-style: italic;
  src: url('/assets/fonts/yoshi-sans-900-italic.woff') format('woff');
}
