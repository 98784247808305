@use '@angular/material' as mat;
@include mat.core();

$mat-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: mat.$blue-palette, // Fallback custom theme colors
  ),
  typography: (
    brand-family: 'YoshiSans',
    plain-family: 'YoshiSans',
    regular-weight: 300,
    medium-weight: 500,
    bold-weight: 700,
  ),
  density: (
    scale: -2,
  )
));

:root {
  // Material Theme Init
  @include mat.all-component-themes($mat-theme);

  // Material Theme Component Density
  @include mat.datepicker-density(0);

  // Material Theme Colors
  --mat-color-primary: var(--ysh-color-blue);
  --mat-color-primary-light: var(--ysh-color-smoke);
  --mat-color-primary-dark: var(--ysh-color-shadow);
  --mat-color-error: var(--ysh-color-coral);
  --mat-color-ripple: rgba(15,35,65,0.1);
  --mat-color-background: rgb(255,255,255);

  // Material Theme Tokens
  --mat-toolbar-container-background-color: var(--mat-color-primary);
  --mat-toolbar-container-text-color: var(--mat-color-primary-light);
  --mdc-protected-button-label-text-color: var(--mat-color-primary);
  --mat-protected-button-state-layer-color: var(--mat-color-primary);
  --mdc-text-button-label-text-color: var(--mat-color-primary);
  --mdc-filled-button-container-color: var(--mat-color-primary);
  --mdc-outlined-button-label-text-color: var(--mat-color-primary);
  --mat-text-button-state-layer-color: var(--mat-color-primary);
  --mat-outlined-button-state-layer-color: var(--mat-color-primary);
  --mdc-protected-button-container-color: var(--mat-color-background);
  --mdc-filled-button-label-text-color: var(--mat-color-primary-light);
  --mdc-outlined-button-outline-color: var(--mat-color-primary-dark);
  --mdc-fab-container-color: var(--mat-color-primary-light);
  --mdc-fab-small-container-color: var(--mat-color-primary-light);
  --mat-outlined-button-ripple-color: var(--mat-color-ripple);
  --mat-icon-button-ripple-color: var(--mat-color-ripple);
  --mat-fab-ripple-color: var(--mat-color-ripple);
  --mat-fab-small-ripple-color: var(--mat-color-ripple);
  --mat-protected-button-ripple-color: var(--mat-color-ripple);
  --mat-text-button-ripple-color: var(--mat-color-ripple);
  --mat-fab-small-foreground-color: var(--mat-color-primary-dark);
  --mat-fab-small-state-layer-color: var(--mat-color-primary-dark);
  --mdc-icon-button-icon-color: var(--mat-color-primary-dark);
  --mat-icon-button-state-layer-color: var(--mat-color-primary-dark);
  --mat-fab-foreground-color: var(--mat-color-primary-dark);
  --mat-fab-state-layer-color: var(--mat-color-primary-dark);
  --mat-text-button-disabled-state-layer-color: var(--mat-color-primary-dark);
  --mat-protected-button-disabled-state-layer-color: var(--mat-color-primary-dark);
  --mat-filled-button-disabled-state-layer-color: var(--mat-color-primary-dark);
  --mat-icon-button-disabled-state-layer-color: var(--mat-color-primary-dark);
  --mat-standard-button-toggle-divider-color: var(--mat-color-primary-dark);
  --mat-standard-button-toggle-selected-state-text-color: var(--mat-color-primary-dark);
  --mat-standard-button-toggle-text-color: var(--mat-color-primary-dark);
  --mat-standard-button-toggle-state-layer-color: var(--mat-color-primary-dark);
  --mat-standard-button-toggle-selected-state-background-color: var(--mat-color-primary-light);
  --mdc-checkbox-selected-focus-icon-color: var(--mat-color-primary);
  --mdc-checkbox-selected-hover-icon-color: var(--mat-color-primary);
  --mdc-checkbox-selected-icon-color: var(--mat-color-primary);
  --mdc-checkbox-selected-pressed-icon-color: var(--mat-color-primary);
  --mdc-checkbox-selected-focus-state-layer-color: var(--mat-color-primary);
  --mdc-checkbox-selected-hover-state-layer-color: var(--mat-color-primary);
  --mdc-checkbox-unselected-pressed-state-layer-color: var(--mat-color-primary);
  --mat-full-pseudo-checkbox-selected-icon-color: var(--mat-color-primary);
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--mat-color-primary);
  --mdc-checkbox-selected-checkmark-color: var(--mat-color-primary-light);
  --mdc-checkbox-unselected-focus-icon-color: var(--mat-color-primary-dark);
  --mdc-checkbox-unselected-hover-icon-color: var(--mat-color-primary-dark);
  --mdc-checkbox-unselected-icon-color: var(--mat-color-primary-dark);
  --mdc-checkbox-unselected-pressed-icon-color: var(--mat-color-primary-dark);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--mat-color-primary-dark);
  --mdc-checkbox-unselected-focus-state-layer-color: var(--mat-color-primary-dark);
  --mdc-checkbox-unselected-hover-state-layer-color: var(--mat-color-primary-dark);
  --mat-full-pseudo-checkbox-unselected-icon-color: var(--mat-color-primary-dark);
  --mat-checkbox-label-text-color: var(--mat-color-primary-dark);
  --mdc-linear-progress-active-indicator-color: var(--mat-color-primary);
  --mdc-circular-progress-active-indicator-color: var(--mat-color-primary);
  --mdc-linear-progress-track-color: var(--mat-color-primary-light);
  --mdc-filled-text-field-caret-color: var(--mat-color-primary);
  --mdc-filled-text-field-focus-active-indicator-color: var(--mat-color-primary);
  --mdc-filled-text-field-focus-label-text-color: var(--mat-color-primary);
  --mdc-outlined-text-field-caret-color: var(--mat-color-primary);
  --mdc-outlined-text-field-focus-outline-color: var(--mat-color-primary);
  --mdc-outlined-text-field-focus-label-text-color: var(--mat-color-primary);
  --mdc-filled-text-field-disabled-label-text-color: var(--mat-color-ripple);
  --mdc-filled-text-field-input-text-color: var(--mat-color-primary-dark);
  --mdc-filled-text-field-disabled-input-text-color: var(--mat-color-ripple);
  --mdc-filled-text-field-hover-active-indicator-color: var(--mat-color-primary-dark);
  --mdc-filled-text-field-input-text-placeholder-color: var(--mat-color-primary-dark);
  --mdc-outlined-text-field-label-text-color: var(--mat-color-primary-dark);
  --mdc-outlined-text-field-hover-label-text-color: var(--mat-color-primary-dark);
  --mdc-outlined-text-field-disabled-label-text-color: var(--mat-color-ripple);
  --mdc-outlined-text-field-input-text-color: var(--mat-color-primary-dark);
  --mdc-outlined-text-field-disabled-input-text-color: var(--mat-color-ripple);
  --mdc-outlined-text-field-input-text-placeholder-color: var(--mat-color-primary-dark);
  --mdc-outlined-text-field-outline-color: var(--mat-color-primary-dark);
  --mdc-outlined-text-field-disabled-outline-color: var(--mat-color-ripple);
  --mdc-outlined-text-field-hover-outline-color: var(--mat-color-primary-dark);
  --mat-form-field-trailing-icon-color: var(--mat-color-primary-dark);
  --mdc-filled-text-field-error-hover-label-text-color: var(--mat-color-error);
  --mdc-filled-text-field-error-focus-label-text-color: var(--mat-color-error);
  --mdc-filled-text-field-error-label-text-color: var(--mat-color-error);
  --mdc-filled-text-field-error-active-indicator-color: var(--mat-color-error);
  --mdc-filled-text-field-error-focus-active-indicator-color: var(--mat-color-error);
  --mdc-filled-text-field-error-hover-active-indicator-color: var(--mat-color-error);
  --mat-form-field-error-text-color: var(--mat-color-error);
  --mdc-outlined-text-field-error-focus-label-text-color: var(--mat-color-error);
  --mdc-outlined-text-field-error-label-text-color: var(--mat-color-error);
  --mdc-outlined-text-field-error-focus-outline-color: var(--mat-color-error);
  --mdc-outlined-text-field-error-hover-outline-color: var(--mat-color-error);
  --mdc-outlined-text-field-error-hover-label-text-color: var(--mat-color-error);
  --mdc-outlined-text-field-error-outline-color: var(--mat-color-error);
  --mat-form-field-error-focus-trailing-icon-color: var(--mat-color-error);
  --mat-form-field-error-hover-trailing-icon-color: var(--mat-color-error);
  --mat-form-field-error-trailing-icon-color: var(--mat-color-error);
  --mdc-filled-text-field-container-color: var(--mat-color-primary-light);
  --mdc-form-field-label-text-color: var(--mat-color-primary-dark);
  --mdc-filled-text-field-label-text-color: var(--mat-color-primary-dark);
  --mdc-filled-text-field-hover-label-text-color: var(--mat-color-primary-dark);
  --mat-form-field-focus-select-arrow-color: var(--mat-color-primary);
  --mat-select-focused-arrow-color: var(--mat-color-primary);
  --mat-select-enabled-trigger-text-color: var(--mat-color-primary-dark);
  --mat-select-disabled-trigger-text-color: var(--mat-color-ripple);
  --mat-select-placeholder-text-color: var(--mat-color-primary-dark);
  --mat-select-enabled-arrow-color: var(--mat-color-primary-dark);
  --mat-option-selected-state-label-text-color: var(--mat-color-primary-dark);
  --mat-option-label-text-color: var(--mat-color-primary-dark);
  --mat-select-invalid-arrow-color: var(--mat-color-error);
  --mat-option-hover-state-layer-color: var(--mat-color-ripple);
  --mat-option-focus-state-layer-color: var(--mat-color-ripple);
  --mat-select-panel-background-color: var(--mat-color-primary-light);
  --mat-option-selected-state-layer-color: var(--mat-color-ripple);
  --mdc-switch-selected-focus-state-layer-color: var(--mat-color-primary);
  --mdc-switch-selected-hover-state-layer-color: var(--mat-color-primary);
  --mdc-switch-selected-pressed-state-layer-color: var(--mat-color-primary);
  --mdc-switch-selected-focus-track-color: var(--mat-color-primary);
  --mdc-switch-selected-hover-track-color: var(--mat-color-primary);
  --mdc-switch-selected-pressed-track-color: var(--mat-color-primary);
  --mdc-switch-selected-track-color: var(--mat-color-primary);
  --mdc-switch-unselected-pressed-track-color: var(--mat-color-primary-light);
  --mdc-switch-unselected-track-color: var(--mat-color-primary-light);
  --mat-switch-track-outline-color: var(--mat-color-primary-dark);
  --mdc-switch-unselected-hover-track-color: var(--mat-color-primary-light);
  --mdc-switch-unselected-icon-color: var(--mat-color-primary-light);
  --mdc-switch-unselected-pressed-handle-color: var(--mat-color-primary-dark);
  --mdc-switch-disabled-selected-icon-color: var(--mat-color-primary-dark);
  --mdc-switch-disabled-selected-track-color: var(--mat-color-primary-dark);
  --mdc-switch-disabled-unselected-handle-color: var(--mat-color-primary-dark);
  --mdc-switch-disabled-unselected-icon-color: var(--mat-color-primary-light);
  --mdc-switch-disabled-unselected-track-color: var(--mat-color-primary-light);
  --mdc-switch-selected-icon-color: var(--mat-color-primary-dark);
  --mdc-switch-unselected-focus-handle-color: var(--mat-color-primary-dark);
  --mdc-switch-unselected-focus-state-layer-color: var(--mat-color-primary-dark);
  --mdc-switch-unselected-focus-track-color: var(--mat-color-primary-light);
  --mdc-switch-selected-focus-handle-color: var(--mat-color-primary-light);
  --mdc-switch-selected-hover-handle-color: var(--mat-color-primary-light);
  --mdc-switch-selected-pressed-handle-color: var(--mat-color-primary-light);
  --mdc-switch-unselected-handle-color: var(--mat-color-primary-dark);
  --mdc-switch-unselected-hover-handle-color: var(--mat-color-primary-dark);
  --mdc-switch-unselected-hover-state-layer-color: var(--mat-color-primary-dark);
  --mdc-switch-selected-handle-color: var(--mat-color-primary-light);
  --mat-switch-label-text-color: var(--mat-color-primary-dark);
  --mdc-radio-selected-focus-icon-color: var(--mat-color-primary);
  --mdc-radio-selected-hover-icon-color: var(--mat-color-primary);
  --mdc-radio-selected-icon-color: var(--mat-color-primary);
  --mdc-radio-selected-pressed-icon-color: var(--mat-color-primary);
  --mat-radio-checked-ripple-color: var(--mat-color-primary);
  --mdc-radio-disabled-selected-icon-color: var(--mat-color-primary-dark);
  --mdc-radio-disabled-unselected-icon-color: var(--mat-color-primary-dark);
  --mdc-radio-unselected-hover-icon-color: var(--mat-color-primary-dark);
  --mdc-radio-unselected-icon-color: var(--mat-color-primary-dark);
  --mdc-radio-unselected-pressed-icon-color: var(--mat-color-primary-dark);
  --mat-radio-label-text-color: var(--mat-color-primary-dark);
  --mdc-slider-handle-color: var(--mat-color-primary);
  --mdc-slider-focus-handle-color: var(--mat-color-primary);
  --mdc-slider-hover-handle-color: var(--mat-color-primary);
  --mdc-slider-active-track-color: var(--mat-color-primary);
  --mdc-slider-label-container-color: var(--mat-color-primary);
  --mat-slider-ripple-color: var(--mat-color-primary);
  --mdc-slider-label-label-text-color: var(--mat-color-primary-light);
  --mdc-slider-with-overlap-handle-outline-color: var(--mat-color-primary-light);
  --mat-slider-hover-state-layer-color: var(--mat-color-ripple);
  --mat-slider-focus-state-layer-color: var(--mat-color-ripple);
  --mdc-list-list-item-selected-trailing-icon-color: var(--mat-color-primary);
  --mdc-list-list-item-leading-avatar-color: var(--mat-color-primary-light);
  --mdc-list-list-item-disabled-state-layer-color: var(--mat-color-primary-dark);
  --mdc-list-list-item-label-text-color: var(--mat-color-primary-dark);
  --mdc-list-list-item-supporting-text-color: var(--mat-color-primary-dark);
  --mdc-list-list-item-leading-icon-color: var(--mat-color-primary-dark);
  --mdc-list-list-item-trailing-supporting-text-color: var(--mat-color-primary-dark);
  --mdc-list-list-item-disabled-label-text-color: var(--mat-color-primary-dark);
  --mdc-list-list-item-disabled-leading-icon-color: var(--mat-color-primary-dark);
  --mdc-list-list-item-disabled-trailing-icon-color: var(--mat-color-primary-dark);
  --mdc-list-list-item-hover-label-text-color: var(--mat-color-primary-dark);
  --mdc-list-list-item-focus-label-text-color: var(--mat-color-primary-dark);
  --mdc-list-list-item-hover-state-layer-color: var(--mat-color-primary-dark);
  --mdc-list-list-item-focus-state-layer-color: var(--mat-color-primary-dark);
  --mat-list-active-indicator-color: var(--mat-color-primary-light);
  --mat-autocomplete-background-color: var(--mat-color-primary-light);
  --mat-expansion-container-background-color: var(--mat-color-background);
  --mat-expansion-container-text-color: var(--mat-color-primary-dark);
  --mat-expansion-header-hover-state-layer-color: var(--mat-color-ripple);
  --mat-expansion-header-focus-state-layer-color: var(--mat-color-ripple);
  --mat-expansion-header-text-color: var(--mat-color-primary-dark);
  --mat-expansion-header-description-color: var(--mat-color-primary-dark);
  --mat-expansion-header-indicator-color: var(--mat-color-primary-dark);
  --mat-paginator-container-text-color: var(--mat-color-primary-dark);
  --mat-paginator-container-background-color: var(--mat-color-background);
  --mat-paginator-enabled-icon-color: var(--mat-color-primary-dark);
  --mat-table-background-color: var(--mat-color-background);
  --mat-table-header-headline-color: var(--mat-color-primary-dark);
  --mat-table-row-item-label-text-color: var(--mat-color-primary-dark);
  --mat-table-row-item-outline-color: var(--mat-color-ripple);
  --mat-sort-arrow-color: var(--mat-color-primary-dark);
  --mdc-elevated-card-container-color: var(--mat-color-background);
  --mat-card-subtitle-text-color: var(--mat-color-primary-dark);
  --mat-menu-container-color: var(--mat-color-primary-light);
  --mat-menu-divider-color: var(--mat-color-ripple);
  --mat-menu-item-label-text-color: var(--mat-color-primary-dark);
  --mat-menu-item-icon-color: var(--mat-color-primary-dark);
  --mat-menu-item-hover-state-layer-color: var(--mat-color-ripple);
  --mat-menu-item-focus-state-layer-color: var(--mat-color-ripple);
  --mdc-tab-indicator-active-indicator-color: var(--mat-color-primary);
  --mat-tab-header-active-focus-indicator-color: var(--mat-color-primary);
  --mat-tab-header-active-hover-indicator-color: var(--mat-color-primary);
  --mat-tab-header-pagination-icon-color: var(--mat-color-primary-dark);
  --mat-tab-header-inactive-label-text-color: var(--mat-color-primary-dark);
  --mat-tab-header-active-label-text-color: var(--mat-color-primary-dark);
  --mat-tab-header-active-ripple-color: var(--mat-color-primary-dark);
  --mat-tab-header-inactive-ripple-color: var(--mat-color-primary-dark);
  --mat-tab-header-inactive-focus-label-text-color: var(--mat-color-primary-dark);
  --mat-tab-header-inactive-hover-label-text-color: var(--mat-color-primary-dark);
  --mat-tab-header-active-focus-label-text-color: var(--mat-color-primary-dark);
  --mat-tab-header-active-hover-label-text-color: var(--mat-color-primary-dark);
  --mat-sidenav-container-background-color: var(--mat-color-background);
  --mat-sidenav-container-text-color: var(--mat-color-primary-dark);
  --mat-sidenav-content-background-color: var(--mat-color-background);
  --mat-sidenav-content-text-color: var(--mat-color-primary-dark);
  --mat-stepper-container-color: var(--mat-color-background);
  --mat-stepper-line-color: var(--mat-color-primary-light);
  --mat-stepper-header-selected-state-icon-foreground-color: var(--mat-color-primary-light);
  --mat-stepper-header-edit-state-icon-foreground-color: var(--mat-color-primary-light);
  --mat-stepper-header-icon-foreground-color: var(--mat-color-primary-light);
  --mat-stepper-header-error-state-label-text-color: var(--mat-color-error);
  --mat-stepper-header-error-state-icon-foreground-color: var(--mat-color-error);
  --mat-stepper-header-hover-state-layer-color: var(--mat-color-ripple);
  --mat-stepper-header-focus-state-layer-color: var(--mat-color-ripple);
  --mat-stepper-header-label-text-color: var(--mat-color-primary-dark);
  --mat-stepper-header-optional-label-text-color: var(--mat-color-primary-dark);
  --mat-stepper-header-selected-state-label-text-color: var(--mat-color-primary-dark);
  --mat-stepper-header-icon-background-color: var(--mat-color-primary-dark);
  --mat-datepicker-calendar-date-selected-state-background-color: var(--mat-color-primary);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: var(--mat-color-primary);
  --mat-datepicker-calendar-date-preview-state-outline-color: var(--mat-color-primary);
  --mat-stepper-header-selected-state-icon-background-color: var(--mat-color-primary);
  --mat-stepper-header-edit-state-icon-background-color: var(--mat-color-primary);
  --mat-datepicker-calendar-date-focus-state-background-color: var(--mat-color-ripple);
  --mat-datepicker-calendar-date-hover-state-background-color: var(--mat-color-ripple);
  --mat-datepicker-calendar-date-in-range-state-background-color: var(--mat-color-primary-light);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: var(--mat-color-primary-light);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: var(--mat-color-primary-light);
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: var(--mat-color-ripple);
  --mat-datepicker-calendar-date-today-outline-color: var(--mat-color-primary);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: var(--mat-color-ripple);
  --mat-datepicker-calendar-date-disabled-state-text-color: var(--mat-color-ripple);
  --mat-datepicker-calendar-date-selected-state-text-color: var(--mat-color-primary-light);
  --mat-datepicker-calendar-container-background-color: var(--mat-color-background);
  --mat-datepicker-toggle-active-state-icon-color: var(--mat-color-primary-dark);
  --mat-datepicker-toggle-icon-color: var(--mat-color-primary-dark);
  --mat-datepicker-calendar-body-label-text-color: var(--mat-color-primary-dark);
  --mat-datepicker-calendar-period-button-text-color: var(--mat-color-primary-dark);
  --mat-datepicker-calendar-period-button-icon-color: var(--mat-color-primary-dark);
  --mat-datepicker-calendar-navigation-button-icon-color: var(--mat-color-primary-dark);
  --mat-datepicker-calendar-header-text-color: var(--mat-color-primary-dark);
  --mat-datepicker-calendar-date-text-color: var(--mat-color-primary-dark);
  --mat-datepicker-range-input-separator-color: var(--mat-color-primary-dark);
  --mat-snack-bar-button-color: white;
  --mdc-chip-outline-color: var(--mat-color-primary-dark);
  --mdc-chip-focus-outline-color: var(--mat-color-primary-dark);
  --mdc-chip-focus-state-layer-color: var(--mat-color-primary-dark);
  --mdc-chip-hover-state-layer-color: var(--mat-color-primary-dark);
  --mdc-chip-selected-hover-state-layer-color: var(--mat-color-primary-dark);
  --mdc-chip-selected-focus-state-layer-color: var(--mat-color-primary-dark);
  --mdc-chip-label-text-color: var(--mat-color-primary-dark);
  --mdc-chip-selected-label-text-color: var(--mat-color-primary-dark);
  --mdc-chip-with-icon-icon-color: var(--mat-color-primary-dark);
  --mdc-chip-with-icon-disabled-icon-color: var(--mat-color-primary-dark);
  --mdc-chip-with-icon-selected-icon-color: var(--mat-color-primary-dark);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: var(--mat-color-primary-dark);
  --mdc-chip-with-trailing-icon-trailing-icon-color: var(--mat-color-primary-dark);
  --mdc-dialog-container-color: var(--mat-color-background);
  --mdc-dialog-subhead-color: var(--mat-color-primary-dark);
  --mdc-dialog-supporting-text-color: var(--mat-color-primary-dark);
  --mat-app-background-color: var(--mat-color-background);
  --mat-app-text-color: var(--mat-color-primary-dark);
  --mat-ripple-color: var(--mat-color-ripple);
}
