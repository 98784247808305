// MATERIAL CUSTOM GLOBAL STYLES

:root {
  --mdc-plain-tooltip-container-color: var(--ysh-color-space);
  --mdc-outlined-text-field-container-shape: var(--ysh-radius-sm);
  --mat-datepicker-calendar-container-shape: var(--ysh-radius-sm);
  --mat-datepicker-calendar-container-elevation-shadow: var(--ysh-shadow-lg);
}

mat-form-field label {
  margin-top: -2px; // tweak vertical alignment
}

mat-datepicker-toggle {
  margin-right: 0.5rem; // more space before field end
}

mat-icon.mat-icon {
  width: fit-content;
  height: fit-content;
  font-size: inherit;

  &[size-small] {
    font-size: 1rem;
  }
  &[size-medium] {
    font-size: 1.125rem;
  }
  &[size-large] {
    font-size: 1.5rem;
  }
}
