@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% { 
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% { 
    opacity: 0;
  }
}
@keyframes slideUp {
  0% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(0);
  }
}
